import { Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private message: NzMessageService) {}

  Error(message: string): Observable<never> {
    this.message.error(message, {
      nzDuration: 5000,
      nzPauseOnHover: true,
      nzAnimate: true,
    });
    return EMPTY;
  }

  Success(message: string): void {
    this.message.success(message);
  }
}
