import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap, createReducer, on } from '@ngrx/store';

import * as RootActions from './root.actions';

export const ROOT_FEATURE_KEY = 'root';

export interface RootState {
  error?: string | null; // last known error (if any)
}

export const INITIAL_STATE: RootState = {};

export const rootReducer = createReducer(
  INITIAL_STATE,
  on(RootActions.initRoot, (state) => ({
    ...state,
    error: null,
  }))
);

// router
export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
};

export interface AppState {
  router?: RouterReducerState;
}
