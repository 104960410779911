import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Location } from '@angular/common';

import { tap } from 'rxjs';

import * as RootActions from './root.actions';
import { ProfileService } from '@tuacar-workspace/core';
@Injectable()
export class RootEffects {
  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.logout),
        tap(() => {
          this.service.logout();
        })
      ),
    { dispatch: false }
  );

  goEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.go),
        tap((action) => {
          this.router.navigateByUrl(action.path);
        })
      ),
    { dispatch: false }
  );

  backEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.back),
        tap(() => this.location.back())
      ),
    { dispatch: false }
  );

  forwardEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.forward),
        tap(() => this.location.forward())
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly service: ProfileService,
    private router: Router,
    private location: Location
  ) {}
}
