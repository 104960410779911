import { Component } from '@angular/core';
import { MenuService } from '@tuacar-workspace/core';

@Component({
  selector: 'tuacar-menu',
  template: `
    <ul nz-menu nzTheme="dark" nzMode="inline">
      <li nz-menu-item [routerLink]="'/anagrafiche/clienti'" nzMatchRouter>
        <span nz-icon nzType="team"></span>
        <span>Clienti</span>
      </li>
      <li nz-menu-item [routerLink]="'/anagrafiche/fornitori'" nzMatchRouter>
        <span nz-icon nzType="team"></span>
        <span>Fornitori</span>
      </li>
      <li nz-menu-item [routerLink]="'/anagrafiche/auto'" nzMatchRouter>
        <span nz-icon nzType="car"></span>
        <span>Elementi Auto</span>
      </li>
      <li nz-menu-item [routerLink]="'/anagrafiche/servizi'" nzMatchRouter>
        <span nz-icon nzType="tool"></span>
        <span>Servizi</span>
      </li>
      <li nz-menu-item [routerLink]="'/anagrafiche/spese'" nzMatchRouter>
        <span nz-icon nzType="euro"></span>
        <span>Spese</span>
      </li>
      <li nz-menu-item [routerLink]="'/anagrafiche/garanzie'" nzMatchRouter>
        <span nz-icon nzType="safety"></span>
        <span>Garanzie</span>
      </li>
      <li
        nz-menu-item
        [routerLink]="'/anagrafiche/assicurazioni'"
        nzMatchRouter
      >
        <span nz-icon nzType="safety"></span>
        <span>Assicurazioni</span>
      </li>
      <li
        nz-menu-item
        [routerLink]="'/anagrafiche/account-pagamento'"
        nzMatchRouter
      >
        <span nz-icon nzType="safety"></span>
        <span>Conti di saldo</span>
      </li>
      <li nz-menu-item [routerLink]="'/anagrafiche/causali'" nzMatchRouter>
        <span nz-icon nzType="safety"></span>
        <span>Causali</span>
      </li>
      <li
        nz-menu-item
        [routerLink]="'/anagrafiche/tipo-pagamento'"
        nzMatchRouter
      >
        <span nz-icon nzType="wallet"></span>
        <span>Tipi pagamento</span>
      </li>
      <li nz-menu-item [routerLink]="'/anagrafiche/iva'" nzMatchRouter>
        <span nz-icon nzType="unordered-list"></span>
        <span>Iva</span>
      </li>
      <!-- <li nz-menu-item [routerLink]="'/anagrafiche/fornitori'" nzMatchRouter>
        <span nz-icon nzType="euro"></span>
        <span>Clienti</span>
      </li> -->
    </ul>
    <!-- <ul
    nz-menu
    nzTheme="dark"
    nzMode="inline"
    [nzInlineCollapsed]="isCollapsed"
  >
    <li nz-submenu nzOpen nzTitle="Auto" nzIcon="car" nzMatchRouter>
      <ul>
        <li
          nz-menu-item
          [routerLink]="'/anagrafiche/auto-colori'"
          nzMatchRouter
        >
          <span>Colori</span>
        </li>
      </ul>
    </li>
  </ul> -->
  `,
  styles: [],
})
export class MenuComponent {
  isCollapsed = false;
  constructor(private menuService: MenuService) {
    this.menuService.isCollapsedChange$.subscribe((isCollapsed) => {
      this.isCollapsed = isCollapsed;
    });
  }
}
