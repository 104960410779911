import { ActionReducerMap } from '@ngrx/store';
import { RouterReducerState, routerReducer } from '@ngrx/router-store';
import { sharedReducer, SharedState } from './shared/shared.reducer';

export interface AppState {
  // auth: AuthState;
  // settings: SettingsState;
  router: RouterReducerState;
  shared: SharedState;
}

export const reducers: ActionReducerMap<AppState> = {
  // settings: settingsReducer,
  router: routerReducer,
  shared: sharedReducer,
  // auth: authReducer
};

export * from './router/router.selectors';
export * from './router/router.actions';
export * from './shared/shared.actions';
export * from './shared/shared.selectors';
export * from './shared/shared.effects';
