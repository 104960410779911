import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lib-tuarent-menu',
  standalone: true,
  imports: [CommonModule],
  template: `<p>tuarent-menu works!</p>`,
  styles: ``,
})
export class TuarentMenuComponent {}
