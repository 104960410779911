import { createAction, props } from '@ngrx/store';
import {
  AgenziaRefModel,
  AnagraficaCountryModel,
  AnagraficaFornitoreModel,
  ClienteTipologiaModel,
  DocumentoTipologiaModel,
  FornitoreTipologiaModel,
  GlobalFilterModel,
  ProfileModel,
  ProfileWhatsappModel,
  TipoPagamentoModel,
} from '../../models/core.model';

export const loadProfile = createAction('[Root/API] Load Profile');

export const loadProfileSuccess = createAction(
  '[Root/API] Load Profile Success',
  props<{ profile: ProfileModel; profileWhatsapp: ProfileWhatsappModel }>(),
);

export const loadProfileFailure = createAction(
  '[Root/API] Load Profile Failure',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ error: any }>(),
);

// WHATSAPP
export const loadWhatsappQrCode = createAction(
  '[Shared] Load Whatsapp Qr Code',
);

export const loadWhatsappQrCodeSuccess = createAction(
  '[Shared] Load Whatsapp Qr Code Success',
  props<{ qrCode: string }>(),
);

export const loadWhatsappQrCodeFailure = createAction(
  '[Shared] Load Whatsapp Qr Code Failure',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ error: any }>(),
);

export const createWhatsappSession = createAction(
  '[Shared] Create Whatsapp Session',
);

export const createWhatsappSessionSuccess = createAction(
  '[Shared] Create Whatsapp Session Success',
  props<{ whatsappSetting: ProfileWhatsappModel }>(),
);

export const createWhatsappSessionFailure = createAction(
  '[Shared] Create Whatsapp Session Failure',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ error: any }>(),
);

export const removeWhatsappSession = createAction(
  '[Shared] Remove Whatsapp Session',
);

export const removeWhatsappSessionSuccess = createAction(
  '[Shared] Remove Whatsapp Session Success',
);

export const removeWhatsappSessionFailure = createAction(
  '[Shared] Remove Whatsapp Session Failure',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ error: any }>(),
);

export const updateProfileWhatsappStatus = createAction(
  '[Shared] Update Profile Whatsapp Status',
  props<{ sessionId: string; name: string; number: string; status: string }>(),
);

// LOAD AGENZIE REF
export const loadAgenzieRef = createAction('[Shared] Load agenzie ref');

export const loadAgenzieRefSuccess = createAction(
  '[Shared] Load agenzie ref Success',
  props<{ data: AgenziaRefModel[] }>(),
);

export const loadAgenzieRefFailure = createAction(
  '[Shared] Load agenzie ref Failure',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ error: any }>(),
);

// LOAD SHARED DATA
export const loadSharedData = createAction('[Shared] Load Shared Data');

export const loadSharedDataSuccess = createAction(
  '[Shared] Load Shared Data Success',
  props<{
    fornitoreTipologia: FornitoreTipologiaModel[];
    clienteTipologia: ClienteTipologiaModel[];
    tipoDocumentiTipologia: DocumentoTipologiaModel[];
    tipoPagamentoList: TipoPagamentoModel[];
    anagraficaFornitoriFinanziaria: AnagraficaFornitoreModel[];
    countries: AnagraficaCountryModel[];
  }>(),
);

export const loadSharedDataFailure = createAction(
  '[Shared] Load Shared Data Failure',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ error: any }>(),
);

export const setGlobalFilters = createAction(
  '[Shared] Set Global Filters',
  props<{ globalFilters: GlobalFilterModel }>(),
);

export const loadGlobalFilters = createAction('[Shared] Load Global Filters');

export const loadAttiAcquistoMissingCount = createAction(
  '[Shared] Load atti acquisto missing count',
);

export const loadAttiAcquistoMissingCountSuccess = createAction(
  '[Shared] Load atti acquisto missing count Success',
  props<{ count: number }>(),
);

export const loadAttiAcquistoMissingCountFailure = createAction(
  '[Shared] Load atti acquisto missing count Failure',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ error: any }>(),
);
