import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MenuService } from '@tuacar-workspace/core';
import { UiModule } from '@tuacar-workspace/ui';

@Component({
  selector: 'tuacar-menu',
  standalone: true,
  imports: [CommonModule, UiModule, RouterModule],
  template: `<ul
    nz-menu
    nzTheme="dark"
    nzMode="inline"
    [nzInlineCollapsed]="isCollapsed"
  >
    <li nz-menu-item [routerLink]="'/statistiche/venduto'" nzMatchRouter>
      <i nz-icon nzType="dashboard" nzTheme="outline"></i>
      <span>Venduto</span>
    </li>
    <li nz-menu-item [routerLink]="'/statistiche/garanzie'" nzMatchRouter>
      <i nz-icon nzType="dashboard" nzTheme="outline"></i>
      <span>Garanzie</span>
    </li>
  </ul>`,
  styles: [],
})
export class StatisticheMenuComponent {
  isCollapsed = false;
  constructor(private menuService: MenuService) {
    this.menuService.isCollapsedChange$.subscribe((isCollapsed) => {
      this.isCollapsed = isCollapsed;
    });
  }
}
