import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  isCollapsed = false;
  isCollapsedChange$ = new BehaviorSubject(false);

  setCollapsed(isCollapsed: boolean) {
    this.isCollapsed = isCollapsed;
    this.isCollapsedChange$.next(isCollapsed);
  }
}
