/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, props } from '@ngrx/store';

export const initRoot = createAction('[Root Page] Init');

export const logout = createAction('[Root/API] Logout');

export const go = createAction('[ROUTER] go', props<{ path: string }>());

export const back = createAction('[ROUTER] back');
export const forward = createAction('[ROUTER] forward');
