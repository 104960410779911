import { Injectable } from '@angular/core';
import * as Ably from 'ably';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  ably?: Ably.Realtime;

  channelFattureAttive?: Ably.RealtimeChannel;
  channelWaha?: Ably.RealtimeChannel;

  init() {
    this.ably = new Ably.Realtime(environment.ablyKey);
    this.channelFattureAttive = this.ably.channels.get('fatture-attive');
    this.channelWaha = this.ably.channels.get('waha-events');
  }

  disconnect() {
    this.ably?.close();
  }
}
