import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SHARED_FEATURE_KEY, SharedState } from './shared.reducer';

export const selectSharedState =
  createFeatureSelector<SharedState>(SHARED_FEATURE_KEY);

export const getProfile = createSelector(
  selectSharedState,
  (state) => state?.profile,
);

export const getProfileWhatsapp = createSelector(
  selectSharedState,
  (state) => state?.profileWhatsapp,
);

export const getWhatsappQrCode = createSelector(selectSharedState, (state) =>
  state.profileWhatsapp?.qrCode
    ? 'data:image/png;base64,' + state.profileWhatsapp?.qrCode
    : undefined,
);

export const selectAgenziaRefList = createSelector(
  selectSharedState,
  (state) => state.agenzieRefList,
);

export const selectAgenziaFatturazioneRefList = createSelector(
  selectSharedState,
  (state) => state.agenzieRefList.filter((agenzia) => agenzia.isFatturazione),
);

export const selectFornitoreTipologiaList = createSelector(
  selectSharedState,
  (state) => state.tipologieFornitori,
);

export const selectClienteTipologiaList = createSelector(
  selectSharedState,
  (state) => state.tipologieClienti,
);

export const selectDocumentiTipologiaList = createSelector(
  selectSharedState,
  (state) => state.tipologiaDocumenti,
);

export const selectAnagraficaCountries = createSelector(
  selectSharedState,
  (state) => state.countries,
);

export const selectAnagraficaFornitoriFinanziaria = createSelector(
  selectSharedState,
  (state) => state.anagraficaFornitoriFinanziaria,
);

export const selectTipoPagamentoList = createSelector(
  selectSharedState,
  (state) => state.tipoPagamentoList,
);

export const isAdmin = createSelector(selectSharedState, (state) => {
  return state?.profile?.roles.some((role) => role.includes('admin'));
});

export const isGlobalAdmin = createSelector(selectSharedState, (state) => {
  return state?.profile?.roles.some((role) => role.includes('global-admin'));
});

export const isSupervisor = createSelector(selectSharedState, (state) => {
  let isSupervisor = false;
  state?.profile?.agenzie.forEach((agenzia) => {
    if (agenzia.ruolo.includes('erp-supervisor')) {
      isSupervisor = true;
    }
  });
  return isSupervisor;
});

export const isBackOffice = createSelector(selectSharedState, (state) => {
  let isBackOffice = false;
  state?.profile?.agenzie.forEach((agenzia) => {
    if (agenzia.ruolo.includes('erp-backoffice')) {
      isBackOffice = true;
    }
  });
  return isBackOffice;
});

export const selectGlobalFilters = createSelector(
  selectSharedState,
  (state) => state.globalFilters,
);

export const selectAttiAcquistoMissingCount = createSelector(
  selectSharedState,
  (state) => state.attiAcquistoMissingCount,
);
