import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import * as SharedActions from './shared.actions';
import { AgenziaService } from '../../services/agenzia.service';
import { forkJoin, of } from 'rxjs';
import { CoreService } from '../../services/core.service';
import * as Sentry from '@sentry/angular';
import { ProfileService } from '../../services/profile.service';
import { Store, select } from '@ngrx/store';
import { selectGlobalFilters } from './shared.selectors';

@Injectable()
export class SharedEffects {
  loadProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.loadProfile),
      switchMap(() =>
        forkJoin({
          user: this.profileService.getUser().pipe(take(1)),
          data: this.profileService.getProfile().pipe(take(1)),
        }).pipe(
          map(({ user, data }) => {
            Sentry.setUser({
              email: user?.email,
              username: user?.nickname,
            });

            localStorage.setItem('user', JSON.stringify(user));

            return SharedActions.loadProfileSuccess({
              profile: {
                firstName: user?.name,
                lastName: user?.family_name,
                mail: user?.email,
                picture: user?.picture,
                tenantDescription: data?.profile?.tenantDescription,
                roles: data?.profile?.roles,
                agenzie: data?.profile?.agenzie,
                userId: data?.profile?.userId,
              },
              profileWhatsapp: data?.profileWhatsapp,
            });
          }),
          catchError((error) =>
            of(
              SharedActions.loadProfileFailure({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  loadAgenzieRef$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.loadAgenzieRef),
      switchMap(() => {
        return this.agenziaService.loadAgenziaRefList().pipe(
          map((response) =>
            SharedActions.loadAgenzieRefSuccess({ data: response }),
          ),
          catchError((error) => {
            //this.snackbarService.Error('Something went wrong.');
            return of(SharedActions.loadAgenzieRefFailure({ error }));
          }),
        );
      }),
    ),
  );

  loadSharedData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.loadSharedData),
      switchMap(() =>
        this.coreService.getSharedData().pipe(
          map((response) =>
            SharedActions.loadSharedDataSuccess({
              fornitoreTipologia: response.fornitoreTipologia,
              clienteTipologia: response.clienteTipologia,
              tipoDocumentiTipologia: response.tipoDocumentiTipologia,
              anagraficaFornitoriFinanziaria:
                response.anagraficaFornitoriFinanziaria,
              tipoPagamentoList: response.tipoPagamentoList,
              countries: response.countries,
            }),
          ),
          catchError((error) => {
            return of(
              SharedActions.loadSharedDataFailure({
                error,
              }),
            );
          }),
        ),
      ),
    ),
  );

  setGlobalFilters$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SharedActions.setGlobalFilters),
        tap((action) => {
          localStorage.setItem(
            'globalFilters',
            JSON.stringify(action.globalFilters),
          );
        }),
      ),
    { dispatch: false },
  );

  loadGlobalFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.loadGlobalFilters),
      map(() => {
        const globalFilters = JSON.parse(
          localStorage.getItem('globalFilters') || '{}',
        );
        return SharedActions.setGlobalFilters({ globalFilters });
      }),
    ),
  );

  loadAttiAcquistoMissingCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.loadAttiAcquistoMissingCount),
      withLatestFrom(this.store.pipe(select(selectGlobalFilters))),
      switchMap(([, globalFilters]) =>
        this.coreService.getAttiAcquistoMissingCount(globalFilters).pipe(
          map((response) =>
            SharedActions.loadAttiAcquistoMissingCountSuccess({
              count: response,
            }),
          ),
          catchError((error) => {
            return of(
              SharedActions.loadAttiAcquistoMissingCountFailure({
                error,
              }),
            );
          }),
        ),
      ),
    ),
  );

  createWhatsappSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.createWhatsappSession),
      switchMap(() =>
        this.profileService.createWhatsappSession().pipe(
          map((response) => {
            return SharedActions.createWhatsappSessionSuccess({
              whatsappSetting: response,
            });
          }),
          catchError((error) => {
            return of(
              SharedActions.createWhatsappSessionFailure({
                error,
              }),
            );
          }),
        ),
      ),
    ),
  );

  removeWhatsappSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.removeWhatsappSession),
      switchMap(() =>
        this.profileService.removeWhatsappSession().pipe(
          map(() => {
            return SharedActions.removeWhatsappSessionSuccess();
          }),
          catchError((error) => {
            return of(
              SharedActions.removeWhatsappSessionFailure({
                error,
              }),
            );
          }),
        ),
      ),
    ),
  );

  loadWhatsappQrCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.loadWhatsappQrCode),
      switchMap(() =>
        this.profileService.getWhatsappQrCode().pipe(
          map((response) => {
            return SharedActions.loadWhatsappQrCodeSuccess({
              qrCode: response,
            });
          }),
          catchError((error) => {
            return of(
              SharedActions.loadWhatsappQrCodeFailure({
                error,
              }),
            );
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private agenziaService: AgenziaService,
    private coreService: CoreService,
    private profileService: ProfileService,
  ) {}
}
