import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { MenuService, isGlobalAdmin } from '@tuacar-workspace/core';
import { UiModule } from '@tuacar-workspace/ui';

@Component({
  selector: 'tuacar-menu',
  standalone: true,
  imports: [CommonModule, UiModule, RouterModule],
  template: `<ul
    nz-menu
    nzTheme="dark"
    nzMode="inline"
    [nzInlineCollapsed]="isCollapsed"
  >
    <li
      nz-menu-item
      [routerLink]="'/settings/tenants'"
      nzMatchRouter
      *ngIf="isGlobalAdmin$$()"
    >
      <span nz-icon nzType="idcard" nzTheme="outline"></span>
      <span>Tenants</span>
    </li>
    <li nz-menu-item [routerLink]="'/settings/agenzie'" nzMatchRouter>
      <i nz-icon nzType="shop" nzTheme="outline"></i>
      <span>Agenzie</span>
    </li>
    <li nz-menu-item [routerLink]="'/settings/utenti'" nzMatchRouter>
      <i nz-icon nzType="user" nzTheme="outline"></i>
      <span>Utenti</span>
    </li>
  </ul>`,
  styles: [],
})
export class SettingsMenuComponent {
  isCollapsed = false;
  isGlobalAdmin$$ = this.store.selectSignal(isGlobalAdmin);
  constructor(
    private menuService: MenuService,
    private store: Store,
  ) {
    this.menuService.isCollapsedChange$.subscribe((isCollapsed) => {
      this.isCollapsed = isCollapsed;
    });
  }
}
