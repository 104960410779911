import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  MenuService,
  isAdmin,
  isBackOffice,
  isSupervisor,
  selectAttiAcquistoMissingCount,
} from '@tuacar-workspace/core';

@Component({
  selector: 'tuacar-menu',
  template: `<ul
    nz-menu
    nzTheme="dark"
    nzMode="inline"
    [nzInlineCollapsed]="isCollapsed"
  >
    <li nz-menu-item [routerLink]="'/erp/dashboard'" nzMatchRouter>
      <i nz-icon nzType="dashboard" nzTheme="outline"></i>
      <span>Dashboard</span>
    </li>
    <li nz-menu-item [routerLink]="'/erp/car-fleet'" nzMatchRouter>
      <i nz-icon nzType="car" nzTheme="outline"></i>
      <span>Parco auto</span>
    </li>
    <li nz-menu-item [routerLink]="'/erp/car-fleet-noleggio'" nzMatchRouter>
      <i nz-icon nzType="car" nzTheme="outline"></i>
      <span>Parco auto a noleggio</span>
    </li>
    <li nz-submenu nzOpen nzTitle="Gestione vendite" nzIcon="audit">
      <ul>
        <li
          nz-menu-item
          [routerLink]="'/erp/gestione-vendite/preventivi'"
          nzMatchRouter
        >
          <span>Preventivi</span>
        </li>
        <li
          nz-menu-item
          [routerLink]="'/erp/gestione-vendite/vendite'"
          nzMatchRouter
        >
          <span>Vendite</span>
        </li>
        <li
          nz-menu-item
          [routerLink]="'/erp/estratto-conto-clienti'"
          nzMatchRouter
        >
          <span>Estratto conto clienti</span>
        </li>
        <li
          nz-menu-item
          [routerLink]="'/erp/estratto-conto-fornitori'"
          nzMatchRouter
        >
          <span>Pagamento fornitori c/terzi</span>
        </li>
      </ul>
    </li>
    <li nz-submenu nzOpen nzTitle="Gestione noleggio" nzIcon="audit">
      <ul>
        <li
          nz-menu-item
          [routerLink]="'/erp/gestione-vendite-noleggio/preventivi'"
          nzMatchRouter
        >
          <span>Preventivi noleggio</span>
        </li>
        <li
          nz-menu-item
          [routerLink]="'/erp/gestione-vendite-noleggio/vendite'"
          nzMatchRouter
        >
          <span>Contratti noleggio</span>
        </li>
        <li
          nz-menu-item
          [routerLink]="'/erp/gestione-vendite-noleggio/estratto-conto-clienti'"
          nzMatchRouter
        >
          <span>Estratto conto clienti</span>
        </li>
      </ul>
    </li>
    <li
      nz-submenu
      nzOpen
      nzTitle="Gestione fatturazione"
      nzIcon="euro"
      *ngIf="(isBackOffice$ | async) || (isAdmin$ | async)"
    >
      <ul>
        <li nz-menu-item [routerLink]="'/erp/fatture-attive'" nzMatchRouter>
          <span>Fatture attive</span>
        </li>
        <li
          nz-menu-item
          [routerLink]="'/erp/provvigioni-finanziarie'"
          nzMatchRouter
        >
          <span>Provvigioni finanziarie</span>
        </li>
        <li
          nz-menu-item
          [routerLink]="'/erp/provvigioni-assicurative'"
          nzMatchRouter
        >
          <span>Provvigioni assicurative</span>
        </li>
        <li nz-menu-item [routerLink]="'/erp/fatture-passive'" nzMatchRouter>
          <span>Fatture passive</span>
        </li>
        <li nz-menu-item [routerLink]="'/erp/atto-acquisto'" nzMatchRouter>
          <span>Atti di acquisto</span>
          <nz-badge
            nzSize="small"
            style="margin-left: 10px;margin-top: -4px;"
            nzStandalone
            [nzCount]="(attiAcquistoMissingCount$ | async) || 0"
          ></nz-badge>
        </li>
        <li
          nz-menu-item
          [routerLink]="'/erp/prima-nota'"
          nzMatchRouter
          *ngIf="(isBackOffice$ | async) || (isAdmin$ | async)"
        >
          <i nz-icon nzType="euro" nzTheme="outline"></i>
          <span>Prima nota</span>
        </li>
        <li
          nz-menu-item
          [routerLink]="'/erp/regime-del-margine'"
          nzMatchRouter
          *ngIf="(isBackOffice$ | async) || (isAdmin$ | async)"
        >
          <i nz-icon nzType="profile" nzTheme="outline"></i>
          <span>Regime del margine</span>
        </li>
      </ul>
    </li>
    <li
      nz-submenu
      nzOpen
      nzTitle="Gestione royalties"
      nzIcon="euro"
      *ngIf="isAdmin$ | async"
    >
      <ul>
        <li
          nz-menu-item
          [routerLink]="'/erp/gestione-garanzie'"
          nzMatchRouter
          *ngIf="isAdmin$ | async"
        >
          <span>Garanzie</span>
        </li>
        <li
          nz-menu-item
          [routerLink]="'/erp/gestione-auto-vendute'"
          nzMatchRouter
          *ngIf="isAdmin$ | async"
        >
          <span>Auto vendute</span>
        </li>
      </ul>
    </li>

    <li
      nz-submenu
      nzOpen
      nzTitle="Registri"
      nzIcon="file-text"
      *ngIf="
        (isBackOffice$ | async) || (isSupervisor$ | async) || (isAdmin$ | async)
      "
    >
      <ul>
        <li
          nz-menu-item
          [routerLink]="'/erp/registri'"
          [queryParams]="{ type: 2 }"
          nzMatchRouter
        >
          <span>Reg. delle cose usate (art. 128)</span>
        </li>
        <li
          nz-menu-item
          [routerLink]="'/erp/registri'"
          [queryParams]="{ type: 1 }"
          nzMatchRouter
        >
          <span>Reg. affari o del comm. (art. 115)</span>
        </li>
      </ul>
    </li>

    <li nz-submenu nzOpen nzTitle="Importazioni esterne" nzIcon="import">
      <ul>
        <li
          nz-menu-item
          [routerLink]="'/erp/external-import/email-from-parse'"
          nzMatchRouter
        >
          <span nz-icon nzType="mail" nzTheme="outline"></span>
          <span>Importa doc. da email</span>
        </li>
      </ul>
    </li>
  </ul>`,
  styles: [],
})
export class MenuComponent {
  isCollapsed = false;
  isSupervisor$ = this.store.select(isSupervisor);
  isBackOffice$ = this.store.select(isBackOffice);
  isAdmin$ = this.store.select(isAdmin);

  attiAcquistoMissingCount$ = this.store.select(selectAttiAcquistoMissingCount);

  constructor(
    private menuService: MenuService,
    private store: Store,
  ) {
    this.menuService.isCollapsedChange$.subscribe((isCollapsed) => {
      this.isCollapsed = isCollapsed;
    });
  }
}
