import { gql } from 'apollo-angular';

export const GET_PROFILE = gql`
  query me {
    me {
      userId
      tenantDescription
      roles
      agenzie {
        code
        description
        indirizzo
        cap
        citta
        provincia
        ruolo
      }
    }
    profileWhatsapp {
      sessionId
      status
      name
      number
    }
  }
`;

export const CREATE_WHATSAPP_SESSION = gql`
  mutation whatsappCreateSession {
    whatsappCreateSession {
      sessionId
      status
      name
      number
      qrCode
    }
  }
`;

export const REMOVE_WHATSAPP_SESSION = gql`
  mutation whatsappRemoveSession {
    whatsappRemoveSession
  }
`;

export const GET_WHATSAPP_QR_CODE = gql`
  query whatsappGetQrCode {
    whatsappGetQrCode
  }
`;

export const GET_ANAGRAFICA_COMUNI = gql`
  query anagraficaComuni($searchValue: String) {
    comuni(limit: 150, searchValue: $searchValue) {
      nodes {
        code
        description
        codeProvincia
        descriptionProvincia
        siglaProvincia
        codeRegione
        descriptionRegione
      }
      totalCount
    }
  }
`;

export const GET_SHARED_DATA = gql`
  query getSharedData {
    fornitoreTipologia {
      id
      name
    }
    clienteTipologia {
      id
      name
    }
    tipoDocumentiTipologia {
      id
      name
    }
    fornitoriFinanziaria {
      id
      name
    }
    tipoPagamenti {
      id
      name
      isFinanziamento
    }
    countries {
      id
      code
      description
    }
  }
`;

export const GET_ATTI_ACQUISTO_MISSING_COUNT = gql`
  query attiAcquistoMissing($agenziaCode: String) {
    cars(
      limit: 9999
      onlySenzaAtto: true
      caricoTipologia: 2
      statuses: [1, 2, 5]
      agenziaCode: $agenziaCode
      alsoAgenzieControllate: true
    ) {
      totalCount
    }
  }
`;
