import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from './root.reducer';

export const getRootState = createFeatureSelector<fromRoot.RootState>(
  fromRoot.ROOT_FEATURE_KEY
);

export const selectRouter = createSelector(
  (state: fromRoot.AppState) => state.router,
  (value) => value
);
