import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import {
  AnagraficaComuneListResponse,
  AnagraficaCountryModel,
  AnagraficaFornitoreModel,
  ClienteTipologiaModel,
  DocumentoTipologiaModel,
  FornitoreTipologiaModel,
  GlobalFilterModel,
  TipoPagamentoModel,
} from '../models/core.model';
import {
  GET_ANAGRAFICA_COMUNI,
  GET_ATTI_ACQUISTO_MISSING_COUNT,
  GET_SHARED_DATA,
} from './queries.graphql';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  constructor(private apollo: Apollo) {}

  findAnagraficaComuni(
    searchValue: string | undefined,
  ): Observable<AnagraficaComuneListResponse> {
    return this.apollo
      .watchQuery({
        query: GET_ANAGRAFICA_COMUNI,
        variables: {
          searchValue: searchValue,

          limit: 150,
        },
        returnPartialData: false,
        fetchPolicy: 'cache-and-network',
      })
      .valueChanges.pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((result: ApolloQueryResult<any>) => {
          const resp = {
            data: result.data.comuni.nodes,
            totalCount: result.data.comuni.totalCount,
          } as AnagraficaComuneListResponse;

          return resp;
        }),
      );
  }

  getSharedData(): Observable<{
    fornitoreTipologia: FornitoreTipologiaModel[];
    clienteTipologia: ClienteTipologiaModel[];
    tipoDocumentiTipologia: DocumentoTipologiaModel[];
    tipoPagamentoList: TipoPagamentoModel[];
    anagraficaFornitoriFinanziaria: AnagraficaFornitoreModel[];
    countries: AnagraficaCountryModel[];
  }> {
    return this.apollo

      .watchQuery({
        query: GET_SHARED_DATA,
        returnPartialData: false,
        fetchPolicy: 'cache-and-network',
      })
      .valueChanges.pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((result: ApolloQueryResult<any>) => {
          const resp = {
            fornitoreTipologia: result.data
              .fornitoreTipologia as FornitoreTipologiaModel[],
            clienteTipologia: result.data
              .clienteTipologia as ClienteTipologiaModel[],
            tipoDocumentiTipologia: result.data
              .tipoDocumentiTipologia as DocumentoTipologiaModel[],
            tipoPagamentoList: result.data
              .tipoPagamenti as TipoPagamentoModel[],
            anagraficaFornitoriFinanziaria: result.data
              .fornitoriFinanziaria as AnagraficaFornitoreModel[],
            countries: result.data.countries as AnagraficaCountryModel[],
          };

          return resp;
        }),
      );
  }

  removeTypename(object: object) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const omitTypename = (key: any, value: any) =>
      key === '__typename' ? undefined : value;

    const newPayload = JSON.parse(JSON.stringify(object), omitTypename);

    return newPayload;
  }

  getAttiAcquistoMissingCount(
    globalFilters?: GlobalFilterModel,
  ): Observable<number> {
    return this.apollo
      .watchQuery({
        query: GET_ATTI_ACQUISTO_MISSING_COUNT,
        variables: {
          agenziaCode: globalFilters?.agenzia?.code,
        },
        returnPartialData: false,
        fetchPolicy: 'cache-and-network',
      })
      .valueChanges.pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((result: ApolloQueryResult<any>) => {
          return result.data.cars.totalCount;
        }),
      );
  }
}
