import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';
import { AuthService, User } from '@auth0/auth0-angular';
import * as Sentry from '@sentry/angular';
import { Apollo, MutationResult } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import {
  CREATE_WHATSAPP_SESSION,
  GET_PROFILE,
  GET_WHATSAPP_QR_CODE,
  REMOVE_WHATSAPP_SESSION,
} from './queries.graphql';
import { ProfileModel, ProfileWhatsappModel } from '../models/core.model';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  getUser(): Observable<User | null | undefined> {
    return this.auth.user$;
  }

  getProfile(): Observable<{
    profile: ProfileModel;
    profileWhatsapp: ProfileWhatsappModel;
  }> {
    return this.apollo
      .watchQuery({
        query: GET_PROFILE,
        returnPartialData: false,
        fetchPolicy: 'cache-and-network',
      })
      .valueChanges.pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((result: ApolloQueryResult<any>) => {
          const user = result.data.me as ProfileModel;
          const whatsapp = result.data.profileWhatsapp as ProfileWhatsappModel;
          return {
            profile: user,
            profileWhatsapp: whatsapp,
          };
        }),
      );
  }

  logout() {
    Sentry.setUser(null);
    localStorage.removeItem('user');

    this.auth.logout({ logoutParams: { returnTo: document.location.origin } });
  }

  createWhatsappSession() {
    return this.apollo
      .mutate({
        mutation: CREATE_WHATSAPP_SESSION,
        fetchPolicy: 'network-only',
      })
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((result: MutationResult) => {
          const image = result.data
            .whatsappCreateSession as ProfileWhatsappModel;
          return image;
        }),
      );
  }

  removeWhatsappSession() {
    return this.apollo
      .mutate({
        mutation: REMOVE_WHATSAPP_SESSION,
        fetchPolicy: 'network-only',
      })
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((result: MutationResult) => {
          return result.data;
        }),
      );
  }

  getWhatsappQrCode(): Observable<string> {
    return this.apollo
      .watchQuery({
        query: GET_WHATSAPP_QR_CODE,
        returnPartialData: false,
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((result: ApolloQueryResult<any>) => {
          const image = result.data.whatsappGetQrCode as string;
          return image;
        }),
      );
  }

  constructor(
    public auth: AuthService,
    private apollo: Apollo,
  ) {}
}
