/* eslint-disable @typescript-eslint/no-unused-vars */

import { Inject, NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { SnackbarService, environment } from '@tuacar-workspace/core';
import extractFiles from 'extract-files/extractFiles.mjs';
import isExtractableFile from 'extract-files/isExtractableFile.mjs';
import { HttpHeaders } from '@angular/common/http';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/angular';

export function createApollo(
  httpLink: HttpLink,
  snackbarService: SnackbarService,
) {
  const uri = environment.graphqlUri;

  const errorHandlerLink = onError((err, ...param) => {
    const { graphQLErrors, networkError } = err;
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path, extensions }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Extensions: ${extensions}`,
        );
        if (extensions && extensions['message']) {
          snackbarService.Error((extensions['message'] as string).toString());
          Sentry.captureMessage((extensions['message'] as string).toString());
        } else {
          snackbarService.Error(message);
          Sentry.captureMessage(message);
        }
      });
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      snackbarService.Error(networkError.message);
      Sentry.captureException(networkError);
    }
  });

  return {
    link: errorHandlerLink.concat(
      httpLink.create({
        uri,
        extractFiles: (body) => extractFiles(body, isExtractableFile),
        headers: new HttpHeaders({
          'GraphQL-Preflight': 1,
        }),
      }),
    ),
    cache: new InMemoryCache({
      addTypename: false,
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, SnackbarService],
    },
  ],
})
export class GraphQLModule {}
