import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { MessageService } from '@tuacar-workspace/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'tuacar-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'TuaCar Manager';
  messageService = inject(MessageService);

  subs: Subscription = new Subscription();

  ngOnInit(): void {
    this.messageService.init();
  }

  ngOnDestroy(): void {
    console.log('AppComponent ngOnDestroy');
    this.messageService.disconnect();
    this.subs.unsubscribe();
  }
}
